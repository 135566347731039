import "antd/dist/antd.css";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import SidebarMenu from "./components/SidebarMenu";
import { Layout, Row, Col } from "antd";
import UserMenu from "./components/UserMenu";
import ApplicationRoutes from "./routes/ApplicationRoutes";
import NavigationBreadcrumb from "./components/NavigationBreadcrumb";

const { Header, Content } = Layout;
const projectName = "Demo Project";

/**
 *
 */
function App() {
  document.title = "Etiq.ai";

  return (
    <Router>
      <Layout style={{ height: "100vh" }}>
        <SidebarMenu />
        <Layout>
          <Header className="siteLayoutBackground" style={{ padding: 0, background: "#001529" }}>
            <Row>
              <Col style={{ textAlign: "right", paddingRight: "12px" }} span={24}>
                <UserMenu />
              </Col>
            </Row>
          </Header>
          <Content style={{ margin: "24px 16px", padding: 12, overflow: "auto", minHeight: "calc(100vh - 114px)" }}>
            {ApplicationRoutes.map(({ path, name, Component }, key) => (
              <Route
                exact
                path={path}
                key={key}
                render={(props) => {
                  const crumbs = ApplicationRoutes.filter(({ path }) => props.match.path.includes(path)).map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length ? Object.keys(props.match.params).reduce((path, param) => path.replace(`:${param}`, props.match.params[param]), path) : path,
                    ...rest,
                  }));

                  console.log(`Generated crumbs for ${props.match.path}`);
                  crumbs.map(({ name, path }) => console.log({ name, path }));

                  return (
                    <div className="p-8">
                      <NavigationBreadcrumb projectName={projectName} crumbs={crumbs} />
                      <Component {...props} />
                    </div>
                  );
                }}
              />
            ))}
          </Content>
        </Layout>
      </Layout>
    </Router>
  );
}

export default App;
