import React, { Fragment } from "react";
import View from "../../View";
import { Card, Row, Col, Table, Tag } from "antd";

function Metrics() {
  const columns = [
    {
      title: "Pipeline ID",
      dataIndex: "run_id",
      key: "run_id",
      align: "center",
      sorter: (a, b) => a.run_id.localeCompare(b.run_id),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => <a href={`/metrics/metricdetails/${record.run_id}/${record.name}/${record.predicted_feature}/${record.demographic}/${record.majority_class}`}>{text}</a>,
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   key: "tags",
    //   align: "center",
    //   render: (tags) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
    },
    {
      title: "Predicted Feature",
      dataIndex: "predicted_feature",
      key: "predicted_feature",
      align: "center",
      render: (predicted_feature) => (
        <>
          {predicted_feature.map((pf) => {
            let color = pf.length > 5 ? "geekblue" : "green";
            if (pf === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={pf}>
                {pf.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Demographic",
      dataIndex: "demographic",
      key: "demographic",
      align: "center",
      render: (demographic) => (
        <>
          {demographic.map((d) => {
            let color = d.length > 5 ? "volcano" : "green";
            if (d === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={d}>
                {d.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Majority Class",
      dataIndex: "majority_class",
      key: "majority_class",
      align: "center",
      render: (majority_class) => (
        <>
          {majority_class.map((mc) => {
            let color = mc.length > 5 ? "red" : "green";
            if (mc === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={mc}>
                {mc.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Algorithm Class",
      dataIndex: "algorithm_class",
      key: "algorithm_class",
      align: "center",
      render: (algorithm_class) => (
        <>
          {algorithm_class.map((ac) => {
            let color = ac.length > 5 ? "red" : "green";
            if (ac === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={ac}>
                {ac.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Equal Opportunity",
      dataIndex: "equal_opportunity",
      key: "equal_opportunity",
      align: "center",
    },
    {
      title: "Demographic Parity",
      dataIndex: "demographic_parity",
      key: "demographic_parity",
      align: "center",
    },
    {
      title: "Equal Odds TPR",
      dataIndex: "equal_odds_tpr",
      key: "equal_odds_tpr",
      align: "center",
    },
    {
      title: "Equal Odds TNR",
      dataIndex: "equal_odds_tnr",
      key: "equal_odds_tnr",
      align: "center",
    },
    {
      title: "Individual Fairness",
      dataIndex: "individual_fairness",
      key: "individual_fairness",
      align: "center",
    },
    {
      title: "Accuracy",
      dataIndex: "accuracy",
      key: "accuracy",
      align: "center",
    },
  ];

  const tableData = [
    {
      run_id: "1",
      name: "Run 1",
      tags: ["risk"],
      start_time: "3 March 2020 (13:52)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["N/A"],
      equal_opportunity: "14%",
      demographic_parity: "20%",
      equal_odds_tpr: "14%",
      equal_odds_tnr: "-7%",
      individual_fairness: "N/A",
      accuracy: "88.78%",
    },
    {
      run_id: "2",
      name: "Run 2",
      tags: ["risk"],
      start_time: "03 March 2020 (16:15)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["N/A"],
      equal_opportunity: "14%",
      demographic_parity: "20%",
      equal_odds_tpr: "14%",
      equal_odds_tnr: "-7%",
      individual_fairness: "0.2",
      accuracy: "88.78%",
    },
    {
      run_id: "3",
      name: "Run 3",
      tags: ["risk"],
      start_time: "06 March 2020 (10:02)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["Logistic Regression"],
      equal_opportunity: "4%",
      demographic_parity: "6%",
      equal_odds_tpr: "N/A",
      equal_odds_tnr: "N/A",
      individual_fairness: "N/A",
      accuracy: "80%",
    },
    {
      run_id: "4",
      name: "Run 4",
      tags: ["risk"],
      start_time: "06 March 2020 (10:02)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["N/A"],
      equal_opportunity: "14%",
      demographic_parity: "20%",
      equal_odds_tpr: "14%",
      equal_odds_tnr: "-7%",
      individual_fairness: "N/A",
      accuracy: "88.78%",
    },
  ];

  return (
    <View>
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Initial Pipeline Metrics">
            <Table columns={columns} dataSource={tableData}></Table>
          </Card>
        </Col>
      </Row>
    </View>
  );
}

export default Metrics;
