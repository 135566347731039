import { Breadcrumb } from "antd";

const NavigationBreadcrumb = ({ projectName, crumbs }) => {
  const foo = (name) => {
    if (name.toLowerCase() === "project") {
      return projectName;
    }

    return name;
  };

  return (
    <Breadcrumb>
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <Breadcrumb.Item key={key}>{foo(name)}</Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={key}>
            <a href={path}>{foo(name)}</a>
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  );
};

export default NavigationBreadcrumb;
