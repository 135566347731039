// import { Pizza, EditPizza, Toppings, AnotherRoute } from "./Components/index";
import ProjectOverview from "../components/Views/ProjectOverview";
import Metrics from "../components/Views/Metrics";
import MetricDetails from "../components/Views/MetricDetails";
import DebiasMetrics from "../components/Views/DebiasMetrics";
import DebiasMetricDetails from "../components/Views/DebiasMetricDetails";
import SafeGuardingMetrics from "../components/Views/SafeGuardingMetrics";
import UserProfile from "../components/Views/UserProfile";
import APIManagement from "../components/Views/APIManagement";

const ApplicationRoutes = [
  { path: "/", name: "Project", Component: ProjectOverview },
  { path: "/metrics", name: "Pipeline Metrics", Component: Metrics },
  { path: "/metrics/metricdetails/:run_id/:name/:predicted_feature/:demographic/:majority_class", name: "Pipeline Detail", Component: MetricDetails },
  { path: "/debiasmetrics", name: "Debias Pipeline Metrics", Component: DebiasMetrics },
  { path: "/debiasmetrics/debiasmetricdetails/:run_id/:name/:predicted_feature/:demographic/:majority_class", name: "Debias Pipeline Details", Component: DebiasMetricDetails },
  { path: "/safeguardingmetrics", name: "Safeguarding Metrics", Component: SafeGuardingMetrics },
  { path: "/userprofile", name: "User Profile", Component: UserProfile },
  { path: "/apimanagement", name: "API Management", Component: APIManagement },
  { path: "/apimanagement/keymanagement", name: "API Management", Component: APIManagement },
];

export default ApplicationRoutes;
