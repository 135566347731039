import React from "react";
import View from "../../View";
import { Card, Row, Col, Tag } from "antd";

function Home() {
  return (
    <View>
      <Row gutter={16} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          <Card title="Project Details">
            <Row gutter={16}>
              <Col span={4}>
                <p style={{ fontWeight: "bold" }}>Project Name :</p>
                <p style={{ fontWeight: "bold" }}>Start Date :</p>
                <p style={{ fontWeight: "bold" }}>End Date :</p>
                <p style={{ fontWeight: "bold" }}>Protected Attribute :</p>
                <p style={{ fontWeight: "bold", paddingBottom: "5px" }}>Sources Of Bias :</p>
              </Col>
              <Col span={6}>
                <p>Demo Project</p>
                <p>1/1/2021 At 7:15 PM</p>
                <p>2/1/2021 At 5:03 AM</p>
                <p>
                  <Tag color="geekblue">Gender</Tag>
                </p>
                <p>
                  <Tag color="volcano">Limited Features</Tag>
                  <Tag color="green">Proxies</Tag>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </View>
  );
}

export default Home;
