import React from "react";
import View from "../../View";
import { Card, Input, Select, Row, Col, Table } from "antd";

function SafeguardingMetrics() {
  const { Option } = Select;

  const preProductionColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      // sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Issue",
      dataIndex: "issue",
      key: "issue",
    },
    {
      title: "Cases Impacted",
      dataIndex: "cases_impacted",
      key: "cases_impacted",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
    },

    {
      title: "Fix",
      dataIndex: "fix",
      key: "fix",
    },
  ];

  const preProductionData = [
    {
      index: "1",
      issue: "Limited Samples",
      cases_impacted: "13.01%",
      details: "No similar applicants of opposite gender are available in the training dataset for the model.",
      fix: "Flag application for Case Management.",
    },
    {
      index: "2",
      issue: "Feature Proxy",
      cases_impacted: "30.45%",
      details: "Feature is used for predictions for the protected demographic but not for the other demographic.",
      fix: "Business rule: do not use feature for impacted segments.",
    },
    {
      index: "3",
      issue: "Feature proxy in cases with negative outcome for the protected demographic",
      cases_impacted: "5.68%",
      details: "Feature is used for predicting a negative outcome for the protected demographic, but not for other demographic.",
      fix: "Business rule: do not use feature for impacted segments.",
    },
  ];

  const impactedColumns = [
    {
      title: "Business Rule For Segment",
      dataIndex: "brs",
      key: "index",
      // sorter: (a, b) => a.name.localeCompare(b.name),
      // sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Segment Mean",
      dataIndex: "sm",
      key: "issue",
      align: "center",
    },
    {
      title: "Dataset Mean",
      dataIndex: "dm",
      key: "cases_impacted",
      align: "center",
    },
    {
      title: "% Females",
      dataIndex: "percent",
      key: "details",
      align: "center",
    },
    {
      title: "Nr Instances In Segment",
      dataIndex: "niis",
      key: "fix",
      align: "center",
    },
    {
      title: "% Of Dataset Size",
      dataIndex: "ds_percent",
      key: "fix",
      align: "center",
    },
    {
      title: "Segment Feature 1",
      dataIndex: "sf1",
      key: "fix",
      align: "center",
    },
    {
      title: "Segment Feature 1 Mean",
      dataIndex: "sf1m",
      key: "fix",
      align: "center",
    },
    {
      title: "Dataset Feature 1 Mean",
      dataIndex: "ds1m",
      key: "fix",
      align: "center",
    },

    {
      title: "Segment Feature 2",
      dataIndex: "sf2",
      key: "fix",
      align: "center",
    },
    {
      title: "Segment Feature 2 Mean",
      dataIndex: "sf2m",
      key: "fix",
      align: "center",
    },
    {
      title: "Dataset Feature 2 Mean",
      dataIndex: "ds2m",
      key: "fix",
      align: "center",
    },

    {
      title: "Segment Feature 3",
      dataIndex: "sf3",
      key: "fix",
      align: "center",
    },
    {
      title: "Segment Feature 3 Mean",
      dataIndex: "sf3m",
      key: "fix",
      align: "center",
    },
    {
      title: "Dataset Feature 3 Mean",
      dataIndex: "ds3m",
      key: "fix",
      align: "center",
    },
  ];

  const impactedData = [
    {
      brs: "RT25",
      sm: "63.44",
      dm: "45.35",
      percent: "0.08",
      niis: "1,197",
      ds_percent: "3.83",
      sf1: "RT20",
      sf1m: "6.46",
      ds1m: "4.37",
      sf2: "D7",
      sf2m: "2.00",
      ds2m: "1.37",
      sf3: "RT25",
      sf3m: "63.44",
      ds3m: "45.3",
    },
    {
      brs: "Age",
      sm: "70.01",
      dm: "55.63",
      percent: "6.68",
      niis: "794",
      ds_percent: "2.54",
      sf1: "Age",
      sf1m: "70.01",
      ds1m: "55.63",
      sf2: "D1",
      sf2m: "1.07",
      ds2m: "1.38",
      sf3: "D7",
      sf3m: "1.06",
      ds3m: "1.37",
    },
    {
      brs: "Age",
      sm: "42.20",
      dm: "55.63",
      percent: "24.27",
      niis: "721",
      ds_percent: "2.31",
      sf1: "D7",
      sf1m: "2.51",
      ds1m: "1.37",
      sf2: "RT20",
      sf2m: "5.86",
      ds2m: "4.37",
      sf3: "Age",
      sf3m: "42.20",
      ds3m: "55.63",
    },
    {
      brs: "RT1",
      sm: "5.17",
      dm: "3.70",
      percent: "0.28",
      niis: "356",
      ds_percent: "1.14",
      sf1: "D7",
      sf1m: "2.22",
      ds1m: "1.37",
      sf2: "RT20",
      sf2m: "6.86",
      ds2m: "4.37",
      sf3: "RT4",
      sf3m: "1.91",
      ds3m: "1.30",
    },
    {
      brs: "RT6",
      sm: "1.94",
      dm: "2.41",
      percent: "18.40",
      niis: "337",
      ds_percent: "1.08",
      sf1: "D7",
      sf1m: "1.86",
      ds1m: "1.37",
      sf2: "RT6",
      sf2m: "1.94",
      ds2m: "2.41",
      sf3: "D4",
      sf3m: "3.83",
      ds3m: "3.35",
    },
    {
      brs: "RT1",
      sm: "2.75",
      dm: "3.70",
      percent: "42.11",
      niis: "285",
      ds_percent: "0.91",
      sf1: "RT1",
      sf1m: "2.75",
      ds1m: "3.70",
      sf2: "RT20",
      sf2m: "3.31",
      ds2m: "4.37",
      sf3: "RT9",
      sf3m: "2.09",
      ds3m: "2.60",
    },
    {
      brs: "RT6",
      sm: "3.00",
      dm: "2.41",
      percent: "5.41",
      niis: "222",
      ds_percent: "0.71",
      sf1: "D7",
      sf1m: "1.92",
      ds1m: "1.37",
      sf2: "D4",
      sf2m: "4.23",
      ds2m: "3.35",
      sf3: "RT6",
      sf3m: "3.00",
      ds3m: "2.41",
    },
    {
      brs: "RT15",
      sm: "3.00",
      dm: "1.88",
      percent: "7.08",
      niis: "113",
      ds_percent: "0.36",
      sf1: "RT15",
      sf1m: "3.00",
      ds1m: "1.88",
      sf2: "D7",
      sf2m: "2.03",
      ds2m: "1.37",
      sf3: "D4",
      sf3m: "4.33",
      ds3m: "3.35",
    },
    {
      brs: "RT9",
      sm: "4.00",
      dm: "2.60",
      percent: "0.00",
      niis: "12",
      ds_percent: "0.04",
      sf1: "RT9",
      sf1m: "4.00",
      ds1m: "2.60",
      sf2: "RT8",
      sf2m: "3.08",
      ds2m: "2.23",
      sf3: "D4",
      sf3m: "4.50",
      ds3m: "3.35",
    },
    {
      brs: "RT15",
      sm: "1.00",
      dm: "1.88",
      percent: "0.00",
      niis: "7",
      ds_percent: "0.02",
      sf1: "RT15",
      sf1m: "1.00",
      ds1m: "1.88",
      sf2: "D1",
      sf2m: "1.00",
      ds2m: "1.38",
      sf3: "RT6",
      sf3m: "3.00",
      ds3m: "2.41",
    },
  ];

  return (
    <View>
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Explanation Pre-production">
            <Table columns={preProductionColumns} dataSource={preProductionData}></Table>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Impacted Customer Segments">
            <Row gutter={16}>
              <Col span={12} style={{ margin: "10px 0px" }}>
                <span style={{ paddingRight: "10px" }}>Select A Problem</span>
                <Select defaultValue="Limited Samples" style={{ width: "40%" }}>
                  <Option value="Limited Samples">Limited Samples</Option>
                  <Option value="Feature Proxy">Feature Proxy</Option>
                  <Option value="Feature Proxy For Negative Outcomes">Feature Proxy For Negative Outcomes</Option>
                </Select>
              </Col>
              <Col span={12} style={{ margin: "10px 0px", textAlign: "right" }}>
                <span style={{ display: "inline-block", paddingRight: "10px" }}>Search</span>
                <Input placeholder="Enter Search Term" style={{ width: "50%", display: "inline-block" }} />
              </Col>
            </Row>

            <Table columns={impactedColumns} dataSource={impactedData}></Table>
          </Card>
        </Col>
      </Row>
    </View>
  );
}

export default SafeguardingMetrics;
