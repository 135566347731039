import React from "react";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const UserMenu = () => {
  const history = useHistory();

  const handleProfileClick = () => {
    history.push("/userprofile");
  };

  return (
    <React.Fragment>
      <div style={{ display: "inline-block" }}>
        <Avatar size={36} style={{ backgroundColor: "", padding: "0", margin: "0" }} onClick={handleProfileClick} className="" icon={<UserOutlined />}>
          {" "}
        </Avatar>
      </div>
      <div style={{ display: "inline-block", paddingLeft: "6px" }}>
        <p style={{ color: "#fff" }}>Raluca</p>
      </div>
    </React.Fragment>
  );
};

export default UserMenu;
