import React from "react";
import { useParams } from "react-router-dom";
import View from "../../View";
import { Card, Row, Col, Tag, Table } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

function DebiasMetricDetails() {
  const { run_id, name, predicted_feature, demographic, majority_class } = useParams();

  const randomDate = (date1, date2) => {
    function randomValueBetween(min, max) {
      return Math.random() * (max - min) + min;
    }
    let startDate = date1 || "01-01-1970";
    let endDate = date2 || new Date().toLocaleDateString();
    startDate = new Date(startDate).getTime();
    endDate = new Date(endDate).getTime();
    if (startDate > endDate) {
      return new Date(randomValueBetween(endDate, startDate)).toLocaleDateString();
    } else {
      return new Date(randomValueBetween(startDate, endDate)).toLocaleDateString();
    }
  };

  const columns = [
    {
      title: "Metric",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Debias Metric Value",
      dataIndex: "debias_value",
      key: "value",
      align: "center",
    },
    {
      title: "Change Metric Value",
      dataIndex: "change_value",
      key: "value",
      align: "center",
    },
  ];

  const data = [
    {
      name: "Equal Opportunity",
      debias_value: "-4%",
      change_value: "-18%",
    },
    {
      name: "Demographic Parity",
      debias_value: "16%",
      change_value: "-13%",
    },
    {
      name: "Equal Odds TPR",
      debias_value: "-4%",
      change_value: "-18%",
    },
    {
      name: "Equal Odds TNR",
      debias_value: "-5%",
      change_value: "-2%",
    },
    {
      name: "Accuracy",
      debias_value: "88%",
      change_value: "-1",
    },
  ];

  return (
    <View>
      <Row gutter={16}>
        <Col span={12}>
          <Card title="Debias Pipeline Details">
            <Row gutter={16}>
              <Col span={12} style={{ fontWeight: "bold" }}>
                <p>Pipeline Number</p>
                <p>Debias Pipeline Number</p>
                <p>Pipline Start Date</p>
                <p>Debias Pipline Start Date</p>
                <p>User</p>
                <p>Status</p>
                <p>Errors Caught</p>
              </Col>
              <Col span={12}>
                <p>{run_id}</p>
                <p>{run_id}</p>
                <p>1st March 2020 (13:52)</p>
                <p>1st March 2020 (15:12)</p>
                <p>Raluca</p>
                <p>
                  {run_id % 2 !== 0 && (
                    <Tag color="green">
                      <CheckOutlined /> Completed
                    </Tag>
                  )}
                  {run_id % 2 === 0 && (
                    <Tag color="red">
                      <CloseOutlined /> Running
                    </Tag>
                  )}
                </p>
                <p>
                  <Tag color="green">0 Errors</Tag>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Debias Pipeline Inputs" style={{ paddingBottom: "33px" }}>
            <Row gutter={16}>
              <Col span={12} style={{ fontWeight: "bold" }}>
                <p>Dataset</p>
                <p>Model</p>
                <p>Predicted Feature</p>
                <p>Demographic</p>
                <p>Majority Class</p>
                <p>&nbsp;</p>
              </Col>
              <Col span={12}>
                <p>dataset</p>
                <p>N/A</p>
                <p>
                  <Tag color="geekblue">{predicted_feature}</Tag>
                </p>
                <p>
                  <Tag color="volcano">{demographic}</Tag>
                </p>
                <p>
                  <Tag color="green">{majority_class}</Tag>
                </p>
                <p>&nbsp;</p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <br />
      <Row gutter={16}>
        <Col span={24}>
          <Card title="Debias Pipeline Metric Details">
            <Table columns={columns} dataSource={data}></Table>
          </Card>
        </Col>
      </Row>
    </View>
  );
}

export default DebiasMetricDetails;
