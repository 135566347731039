import React, { useEffect, useState } from "react";
import { Menu, Layout } from "antd";
import { useHistory } from "react-router-dom";
import { DeploymentUnitOutlined, LeftSquareOutlined, RightSquareOutlined, FundProjectionScreenOutlined, WarningOutlined, DatabaseOutlined } from "@ant-design/icons";
import icon from "../../images/logos/icon.png";

const { Sider } = Layout;

/**
 * Component which renders the main layout's collapsible sidebar, navigation, menu.
 *
 * @component
 * @param {Boolean} A boolean used to indicate the collapsed state of the menu.
 */
const SidebarMenu = () => {
  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const history = useHistory();

  /**
   * useEffect Hook which is used to set the initial collapsed state of the CollapsibleMenu component.
   */
  useEffect(() => {
    setMenuCollapsed(window.innerWidth <= 760);
  }, []);

  /**
   * Toggles the CollapsibleMenu's collapsed state. If it was expanded then it will collapse or vis versa.
   *
   * @param {Element} e The collapse/expand button element in the layout header.
   */
  const toggleMenuCollapsedState = (e) => {
    e.preventDefault();
    setMenuCollapsed(!menuCollapsed);
  };

  const handleProjectOverviewClick = () => {
    history.push("/");
  };
  const handleMetricsClick = () => {
    history.push("/metrics");
  };
  const handleDebiasMetricsClick = () => {
    history.push("/debiasmetrics");
  };
  const handleSafeguardingMetricsClick = () => {
    history.push("/safeguardingmetrics");
  };

  return (
    // <Sider trigger={null} collapsible collapsed={menuCollapsed} style={{ zIndex: "100", overflow: "auto", height: "100vh", position: "fixed", left: "0" }}>
    <Sider trigger={null} collapsible collapsed={menuCollapsed}>
      <div style={{ height: "28px", margin: "16px" }}>
        <img src={icon} alt="Etiq.ai" />
        <h1
          style={{
            color: "#fff",
            display: menuCollapsed ? "none" : "inline-block",
            fontSize: "22px",
            fontWeight: "bold",
            paddingLeft: "10px",
            border: "solid 1px transparent",
            height: "40px",
            verticalAlign: "top",
          }}
        >
          Etiq.ai
        </h1>
      </div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        <Menu.Item key="1" onClick={handleProjectOverviewClick}>
          <FundProjectionScreenOutlined />
          <span> Project Overview</span>
        </Menu.Item>
        <Menu.Item key="2" onClick={handleMetricsClick}>
          <DeploymentUnitOutlined />
          <span> Pipelines</span>
        </Menu.Item>
        <Menu.Item key="3" onClick={handleDebiasMetricsClick}>
          <DatabaseOutlined />
          <span> Debias Pipelines</span>
        </Menu.Item>
        <Menu.Item key="4" onClick={handleSafeguardingMetricsClick}>
          <WarningOutlined />
          <span> Safeguarding</span>
        </Menu.Item>
      </Menu>
      <div style={{ bottom: "10px", width: "100%", position: "absolute" }}>
        {React.createElement(menuCollapsed ? RightSquareOutlined : LeftSquareOutlined, {
          className: "trigger",
          onClick: toggleMenuCollapsedState,
          style: { color: "#fff" },
        })}
      </div>
    </Sider>
  );
};

export default SidebarMenu;
