import React, { Fragment } from "react";

const View = ({ children }) => {
  return (
    <Fragment>
      <div style={{ paddingTop: "5px", paddingBottom: "5px" }}>{children}</div>
    </Fragment>
  );
};

export default View;
