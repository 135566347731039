import React from "react";
import View from "../../View";
import { Card, Row, Col, Table, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";

function DebiasMetrics() {
  const columns = [
    {
      title: "Pipeline ID",
      dataIndex: "run_id",
      key: "run_id",
      align: "center",
      sorter: (a, b) => a.run_id.localeCompare(b.run_id),
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   title: "Name",
    //   dataIndex: "name",
    //   key: "name",
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    {
      title: "Debias ID",
      dataIndex: "debias_id",
      key: "debias_id",
      align: "center",
      sorter: (a, b) => a.run_id.localeCompare(b.run_id),
      render: (text, record) => <a href={`/debiasmetrics/debiasmetricdetails/${record.run_id}/${record.name}/${record.predicted_feature}/${record.demographic}/${record.majority_class}`}>{text}</a>,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    // {
    //   title: "Debias Name",
    //   dataIndex: "debias_name",
    //   key: "debias_name",
    //   render: (text, record) => <a href={`/debiasmetrics/debiasmetricdetails/${record.run_id}/${record.name}/${record.predicted_feature}/${record.demographic}/${record.majority_class}`}>{text}</a>,
    //   sorter: (a, b) => a.name.localeCompare(b.name),
    //   sortDirections: ["ascend", "descend", "ascend"],
    // },
    // {
    //   title: "Tags",
    //   dataIndex: "tags",
    //   key: "tags",
    //   render: (tags) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? "geekblue" : "green";
    //         if (tag === "loser") {
    //           color = "volcano";
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
      align: "center",
    },
    {
      title: "Predicted Feature",
      dataIndex: "predicted_feature",
      key: "predicted_feature",
      align: "center",
      render: (predicted_feature) => (
        <>
          {predicted_feature.map((pf) => {
            let color = pf.length > 5 ? "geekblue" : "green";
            if (pf === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={pf}>
                {pf.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Demographic",
      dataIndex: "demographic",
      key: "demographic",
      align: "center",
      render: (demographic) => (
        <>
          {demographic.map((d) => {
            let color = d.length > 5 ? "volcano" : "green";
            if (d === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={d}>
                {d.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Majority Class",
      dataIndex: "majority_class",
      key: "majority_class",
      align: "center",
      render: (majority_class) => (
        <>
          {majority_class.map((mc) => {
            let color = mc.length > 5 ? "red" : "green";
            if (mc === "loser") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={mc}>
                {mc.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Algorithm Class",
      dataIndex: "algorithm_class",
      key: "algorithm_class",
      align: "center",
      render: (algorithm_class) => (
        <>
          {algorithm_class.map((ac) => {
            let color = ac.length > 5 ? "red" : "green";
            if (ac === "loser") {
              color = "volcano";
            }
            return (
              <Tag color="pink" key={ac}>
                {ac.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Completed",
      dataIndex: "completed",
      key: "completed",
      align: "center",
      render: () => <CheckOutlined />,
    },
    {
      title: "Debias Accuracy",
      dataIndex: "debias_accuracy",
      key: "debias_accuracy",
      align: "center",
    },
    {
      title: "Change Accuracy",
      dataIndex: "change_accuracy",
      key: "change_accuracy",
      align: "center",
    },
  ];

  const data = [
    {
      run_id: "1",
      name: "First Run",
      debias_id: "1",
      debias_name: "Repair 1",
      tags: ["risk"],
      start_time: "01 March 2020 (15:12)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["XGB"],
      completed: 1,
      debias_accuracy: "88%",
      change_accuracy: "-1%",
    },
    {
      run_id: "1",
      name: "First Run",
      debias_id: "2",
      debias_name: "Repair 1",
      tags: ["risk"],
      start_time: "01 March 2020 (16:15)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["XGB"],
      completed: 1,
      debias_accuracy: "87%",
      change_accuracy: "-2%",
    },
    {
      run_id: "1",
      name: "First Run",
      debias_id: "3",
      debias_name: "Repair 1",
      tags: ["risk"],
      start_time: "02 March 2020 (09:33)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["XGB"],
      completed: 1,
      debias_accuracy: "87%",
      change_accuracy: "-2%",
    },
    {
      run_id: "1",
      name: "First Run",
      debias_id: "4",
      debias_name: "Repair 1",
      tags: ["risk"],
      start_time: "03 March 2020 (12:42)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["XGB"],
      completed: 1,
      debias_accuracy: "86%",
      change_accuracy: "-3%",
    },
    {
      run_id: "1",
      name: "First Run",
      debias_id: "5",
      debias_name: "Repair 1",
      tags: ["risk"],
      start_time: "04 March 2020 (13:53)",
      predicted_feature: ["Income"],
      demographic: ["Gender"],
      majority_class: ["Male"],
      algorithm_class: ["XGB"],
      completed: 1,
      debias_accuracy: "88%",
      change_accuracy: "-1%",
    },
  ];

  return (
    <View>
      <Row gutter={16}>
        <Col span={24}>
          {/* <Card title="Bias Metrics" extra={<a href="#">Details</a>}> */}
          <Card title="Debias Pipeline Metrics">
            <Table columns={columns} dataSource={data}></Table>
          </Card>
        </Col>
      </Row>
    </View>
  );
}

export default DebiasMetrics;
